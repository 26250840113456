<template>
  <div class="py-14 my-auto">
    <SectionHeader :title="`${ lender ? lender.name : 'Our partner banks' } is happy to fund you`"/>
    <AppCheckbox class="-mt-5" v-model="accepted">I agree to share data that helps me get credit</AppCheckbox>
    <div class="flex mt-10">
      <AppButton :disabled="!accepted" @click="$emit('done')">Continue</AppButton>
      <button
        v-if="inIframe" @click="cancel()"
        class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button";
import SectionHeader from "@/components/global/SectionHeader";
import AppCheckbox from "@/components/global/AppCheckbox";

export default {
  name: "Preload",
  components: { AppCheckbox, SectionHeader, AppButton },
  data() {
    return {
      accepted: false,
      lender: this.$store.state.lender,
      inIframe: false
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
  },
  methods: {
    cancel() {
      this.$bus.$emit('cancel');
    },
  }
}
</script>
