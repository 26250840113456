<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="flex flex-col h-full overflow-y-auto pb-16 pt-8">
      <div class="my-auto">
        <SectionHeader
          title="Let's get to know you"
          subtitle="Please fill in all fields correctly"
        />
        <form>
          <ValidationObserver ref="observer">
            <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
            <div class="flex flex-col space-y-4">
              <template v-if="$store.state.profile.user_id">
                <div>
                  <label class="flex mb-2 text-gray-600">Full name</label>
                  <AppInput :value="values.full_name" type="text" class="w-full" disabled/>
                </div>
                <div>
                  <label class="flex mb-2 text-gray-600">Email address</label>
                  <AppInput :value="values.email" type="email" class="w-full" disabled/>
                </div>
                <div>
                  <label class="flex mb-2 text-gray-600">Phone number</label>
                  <AppInput :value="values.phone" type="text" class="w-full" disabled/>
                </div>
              </template>
              <template v-else>
                <ValidationProvider name="Full name" rules="required" v-slot="{ errors }">
                  <label class="flex mb-2 text-gray-600">Full name</label>
                  <AppInput
                    v-model="values.full_name" type="text" :errors="errors" class="w-full" :disabled="loading"
                  />
                </ValidationProvider>
                <ValidationProvider name="Email address" rules="required|email" v-slot="{ errors }">
                  <label class="flex mb-2 text-gray-600">Email address</label>
                  <AppInput
                    v-model="values.email" type="email" :errors="errors" class="w-full" :disabled="loading"
                  />
                </ValidationProvider>
                <ValidationProvider name="Phone number" rules="required|phone" v-slot="{ errors }">
                  <label class="flex mb-2 text-gray-600">Phone number</label>
                  <AppInput
                    v-model="values.phone" type="tel" :errors="errors" class="w-full" :disabled="loading"
                  />
                </ValidationProvider>
              </template>
              <ValidationProvider name="occupation_id" rules="required" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Employment status</label>
                <AppSelect v-model="values.occupation_id" :errors="errors" class="w-full" :disabled="loading">
                  <option disabled selected>Select employment status</option>
                  <option :value="status.value" v-for="status in occupations" :key="status.value">
                    {{ status.text }}
                  </option>
                </AppSelect>
              </ValidationProvider>
              <ValidationProvider name="Place of work" rules="required" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Place of work</label>
                <AppInput
                  v-model="values.company_name" type="text" :errors="errors" class="w-full"
                  :disabled="loading"
                />
              </ValidationProvider>
            </div>
            <div class="flex mt-10">
              <AppButton @click="submit()" :loading="loading">
                {{ $store.state.profile.user_id ? 'Get offer' : 'Continue' }}
              </AppButton>
            </div>
          </ValidationObserver>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import ajax from "@/mixins/ajax.js";
import Message from "@/components/global/Message.vue";
import axios from "axios";

const occupations = [
  { value: "1", text: "Employed" },
  { value: "4", text: "Employer" },
  { value: "2", text: "Entrepreneur" },
  { value: "20", text: "Retired" },
  { value: "5", text: "Self Employed" },
  { value: "3", text: "Student" },
  { value: "7", text: "Unemployed" }
];

export default {
  name: "ConsentProfile",
  components: { Message, AppButton, AppInput, AppSelect, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      loading: false,
      values: {
        full_name: '',
        email: '',
        phone: '',
        company_name: '',
        occupation_id: ''
      },
      occupations
    }
  },
  created() {
    const { full_name, email, phone } = this.$store.state.profile;
    const { company_name, occupation_id } = this.$store.state.work;
    this.values = { full_name, email, phone, company_name, occupation_id };
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      const { email, full_name, phone, company_name, occupation_id } = this.values;
      if (!this.$store.state.profile.user_id) {
        this.loading = true;
        let data = {};
        data = await this.checkUser(email, phone);
        if (!data.user_id && !this.error) data = await this.createUser(this.data.profile);
        if (data.user_id && data.token) {
          this.$store.commit('patch', { token: data.token });
          this.$store.commit('profile', { user_id: data.user_id, email, full_name, phone });
          this.$store.commit("work", { company_name, occupation_id });
          await this.$store.dispatch('getUserDetails');
          if (!this.$store.state.extra.school_request_id) {
            await this.createStudentApplication({ full_name, email, phone });
          }
        }
        this.loading = false;
      } else {
        this.$store.commit("profile", { email, full_name, phone });
        this.$store.commit("work", { company_name, occupation_id });
      }
      this.$emit("done");
    },
    async checkUser(email, phone) {
      try {
        const { data } = await this.$http.post(`/customer/check/details`, { email, phone });
        const { token, user_id } = data;
        return { token, user_id };
      } catch (e) {
        console.log(e);
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async createUser({ full_name, email, phone }) {
      try {
        const payload = {
          profile: { full_name, email, phone, password: 'temp', is_individual: 1 }
        };
        const res = await this.$http.post('/customer/register/user', payload);
        this.$store.commit('patch', { new_customer: true });
        const { token, user_id } = res.data;
        return { token, user_id };
      } catch (e) {
        console.log(e)
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async createStudentApplication({ full_name, email, phone }) {
      const { request, extra, token } = this.$store.state;
      const payload = {
        profile: {
          legal_name: full_name,
          email, phone
        },
        request: {
          amount: request.amount,
          tenor: request.duration,
          tenor_type: '2',
          product_id: '29955',
          course_name: extra.course.title,
          course_id: extra.course.id
        },
        admin: {
          user_id: token
        }
      };
      try {
        const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/createmsbmstudent', payload);
        await this.saveEligibilityLink(res.data);
      } catch (e) {
        this.setError(e?.response ? e.response.data?.message : e.message || 'An error occurred, please try again');
      }
    },
    async saveEligibilityLink(response) {
      const { school_request_id, parent_id, school_id } = response;
      const payload = await this.generateEligibilityPayload(response);
      await this.updateEligibilityLink(school_request_id, payload);
      const { extra } = this.$store.state;
      this.$store.commit('patch', {
        extra: {
          ...extra, parent_request_id: school_request_id, parent_id, school_id
        }
      })
    },
    async updateEligibilityLink(school_request_id, payload) {
      await axios.post(`https://sellbackend.creditclan.com/parent/index.php/globalrequest/updateapplication`, {
        id: school_request_id, payload
      });
    },
    async generateEligibilityPayload(response) {
      try {
        const global_settings = await this.getGlobalSetting();
        const { lender_id, lender_name, image: lender_image } = global_settings?.funding_partner ?? {};
        const lender = { id: lender_id, name: lender_name, image: lender_image };
        const {
          school_request_id, school_id, parent_id,
          data: {
            profile: { legal_name: full_name, ...profile },
            request: { amount }
          },
          course: { tenor, tenor_type }
        } = response;
        const { course } = this.$store.state.extra;
        return {
          banner: 'https://merchants.creditclan.com/assets/images/man-smiling.jpeg',
          request: { amount, tenor, tenor_type, product_id: '29955' },
          profile: { ...profile, full_name },
          lender,
          consent: false,
          extra: {
            parent_request_id: school_request_id,
            school_id,
            parent_id,
            course: {
              title: course.title,
              description: course.description,
              duration: course.duration,
              amount: course.amount,
              image: course.image
            }
          }
        };
      } catch (e) {
        console.log('Generate error', e);
      }
    },
    async getGlobalSetting() {
      try {
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/bnpl/global/settings', {}, {
          headers: { 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' }
        });
        return res.data.response ?? {};
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  }
}
</script>
