<template>
  <div class="my-auto">
    <template v-if="offers.length">
      <p class="mb-6 text-lg text-gray-700">Review and accept offer</p>
      <div class="bg-gray-50 shadow-md border rounded-lg p-6 h-80 flex flex-col mt-8 max-w-sm">
        <p class="text-gray-600">Pay</p>
        <div class="text-xl font-semibold">{{ offers[0].upfront | currency }} <small
          class="text-gray-600">now</small></div>
        <div v-if="offers[0].duration === 1" class="text-gray-700">
          and {{ offers[0].monthly_repayment | currency }} next month
        </div>
        <div v-else class="text-gray-700">
          and {{ offers[0].monthly_repayment | currency }}/mo for {{ offers[0].duration }} months
        </div>
        <div class="flex justify-end items-center mt-auto text-gray-600">
          <i class="fa fa-user mr-2"></i>Powered by {{ lender ? lender.name : offers[0].lender }}
        </div>
      </div>
      <AppButton class="mt-10" @click="$emit('accept', offers[0])" :loading="accepting">Accept</AppButton>
    </template>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button.vue";
import { formatCurrency, roundToNearestTen } from "@/lib/utils.js";

export default {
  name: "SchoolOffers",
  components: { AppButton },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      frequencies: [
        { id: 2, text: 'month(s)', adverb: 'Monthly' },
        { id: 4, text: 'week(s)', adverb: 'Weekly' },
      ],
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.lender
    }
  },
  async created() {
    this.offers = [{ ...this.offer }];
  },
  methods: {
    changeTenorType(v) {
      this.selectedOffer.tenor_type = v;
      const offer = this.offers.find(offer => offer.id === this.selectedOffer.id);
      if (v === 4) {
        this.selectedOffer.duration = offer.duration * 4
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 4)
      }
      if (v === 1) {
        this.selectedOffer.duration = offer.duration * 30
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 30)
      }
      if (v === 2) {
        this.selectedOffer.duration = offer.duration
        this.selectedOffer.monthly_repayment = offer.monthly_repayment
      }
    },
  },
  computed: {
    tenor_type() {
      return this.frequencies.find(f => this.selectedOffer.tenor_type === f.id) || {}
    }
  }
}
</script>
