<template>
  <div class="h-screen md:p-4 lg:p-12 bg-gray-900 bg-opacity-70">
    <div class="grid grid-cols-12 overflow-hidden h-full max-w-7xl mx-auto md:rounded-lg lg:rounded-xl">
      <div
        class="hidden md:flex lg:flex md:col-span-3 lg:col-span-6 bg-blue-600 bg-center"
        :style="{ background: `linear-gradient(to right, rgb(58 75 168 / 60%), rgb(160 66 157 / 60%)), url(${banner}) center no-repeat`, backgroundSize: 'cover' }"
      />
      <div class="col-span-12 md:col-span-9 lg:col-span-6 flex flex-col bg-white relative overflow-hidden">
        <img
          src="@/assets/images/pattern-up.png" alt="background pattern" class="absolute top-0 left-0"
          style="width: 300px;"
        />
        <img
          src="@/assets/images/pattern-down.png" alt="background pattern" class="absolute bottom-0 right-0"
          style="width: 300px"
        />
        <div class="h-full overflow-y-auto p-6 lg:py-10 lg:px-12 flex flex-col z-50">
          <template v-if="!cancelling">
            <Preload v-if="preload" @done="preload = false"/>
            <template v-else>
              <Loader v-if="loading" class="my-auto" :text="loading"/>
              <Error v-else-if="error" :text="error" class="my-auto" show-retry @retry="init()"/>
              <Analysis v-else/>
            </template>
          </template>
          <div v-else class="my-auto">
            <SectionHeader title="Are you sure you want to cancel this request?"/>
            <div class="flex mt-8">
              <AppButton @click="cancelling = false">No</AppButton>
              <button
                @click="cancel()"
                class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2"
              >
                Yes, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import Analysis from "@/components/core/Analysis.vue";
import banks from "@/lib/banks";
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error";
import Preload from "@/components/shared/Preload";
import { decodeToken } from "@/lib/utils";
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import axios from "axios";

export default {
  components: { AppButton, SectionHeader, Preload, Error, Analysis, Loader },
  mixins: [ajax],
  data() {
    return {
      loading: false,
      banner: '',
      data: null,
      preload: false,
      cancelling: false
    }
  },
  created() {
    this.setup();
    this.$bus.$on('cancel', () => {
      this.cancelling = true;
    });
  },
  mounted() {
    parent.postMessage({ id: 'cclan-frame-loaded' }, '*');
  },
  methods: {
    setup() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (!token) return this.setError('Something went wrong');
      const decoded = decodeToken(token);
      const {
        request, profile = {}, request_id, home_address = { address: '', state: '', lga: '' },
        identity = { nin: '' }, extra = {}, banner, email_verified = false, work = {},
        lender = { lender_id: "40245", lender_name: "Creditclan", image: "https://creditclan.com/images/logo-home.png" }
      } = decoded;
      this.banner = extra?.course?.image || banner || 'https://images.unsplash.com/photo-1572666341285-c8cb9790ca50?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
      this.$store.commit('patch', {
        request: { ...request, tenor_type: request?.tenor_type || 2 }, identity,
        profile, home_address, request_id, extra, lender, email_verified, work
      });
      this.data = { profile: { ...profile, ...home_address, email_verified }, request };
      this.init();
    },
    async init() {
      try {
        this.setLoading('Just a moment..');
        let data = {};
        const { full_name, email, phone } = this.$store.state.profile;
        if (full_name && email && phone) {
          data = await this.checkUser(this.data.profile.email, this.data.profile.phone);
          if (!data.user_id && !this.error) data = await this.createUser(this.data.profile);
          if (data.user_id && data.token) {
            this.$store.commit('patch', { token: data.token });
            this.$store.commit('profile', { user_id: data.user_id });
            await this.getUserDetails(data.token);
            this.$store.commit('patch', { request: this.data.request });
            if (!this.$store.state.has_data) this.$store.dispatch('postData', { type: 'phone' });
            await this.getCreditclanRequestId();
          }
        }
        this.setLoading('');
      } catch (e) {
        this.setError('An error occurred, please try again!', true)
      }
    },
    async getCreditclanRequestId() {
      const { extra, request_id } = this.$store.state;
      if (!request_id) await this.getParentCcId(extra.parent_request_id);
      if (this.$store.state.request_id) await this.getRequestDetails();
    },
    async getRequestDetails() {
      try {
        const { token, request_id } = this.$store.state;
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/loan/details', {
          token, request_id
        });
        const { stage, offers, offer_selected } = res.data.data.loan;
        if (offer_selected) {
          const payment = { amount: offer_selected.upfront, upfront: true };
          return this.$store.commit('patch', { stage, payment, offer: offer_selected });
        }
        let [offer] = offers ?? [];
        if (offer) {
          offer = {
            ...offer,
            upfront: +(offer.upfront.replace(/,/gi, '')),
            amount: +(offer.amount.replace(/,/gi, '')),
            monthly_repayment: +(offer.monthly_repayment.replace(/,/gi, '')),
          }
        }
        this.$store.commit('patch', { stage, offer });
      } catch (e) {
        this.$store.commit('patch', { request_id: null });
        console.log(e);
      }
    },
    async getParentCcId(parent_request_id) {
      try {
        const res = await axios.post('https://sellbackend.creditclan.com/parent/index.php/external/get_cc_request', { parent_request_id });
        const { creditclan_request_id } = res.data.data;
        this.$store.commit('patch', { request_id: creditclan_request_id });
      } catch (e) {
        console.log(e);
      }
    },
    async checkUser(email, phone) {
      try {
        const { data } = await this.$http.post(`/customer/check/details`, { email, phone });
        const { token, user_id } = data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async createUser(data) {
      const { email_verified = false, ...rest } = data;
      try {
        const payload = {
          profile: { ...rest, password: 'temp', is_individual: 1 }
        };
        if (email_verified) payload.email_verified = true;
        const res = await this.$http.post('/customer/register/user', payload);
        this.$store.commit('patch', { new_customer: true });
        const { token, user_id } = res.data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async getUserDetails(token) {
      try {
        const res = await this.$http.post('/user/detailsbyid', { token });
        const {
          profile: { file_name, phone, legal_name: full_name, email, date_of_birth, gender },
          home_address: { home_address, home_state },
          bvn: { bvn },
          work: {
            net_monthly_income,
            company_name,
            work_sector,
            start_month,
            start_year,
            occupation_id,
            work_address,
            official_email,
            work_email_verified
          },
          accounts
        } = res.data.data.userData.data;
        const { has_data, last_application_date, frequently_called_numbers: fcn, data, whatsapp_id } = res.data;
        let profile_image = this.$store.state.profile.profile_image || file_name;
        if (profile_image === 'https://res.cloudinary.com/du1mqyww8/image/upload/v1617623098/wnwad3hebiais8ulr5yy.png') profile_image = null;
        this.$store.commit('profile', { profile_image, phone, full_name, email, date_of_birth, gender });
        this.$store.commit('account', { bvn });
        const frequently_called_numbers = fcn || [{ phone: '' }, { phone: '' }];
        this.$store.commit('patch', {
          work: {
            monthly_income: net_monthly_income,
            company_name,
            work_sector,
            start_month,
            start_year,
            occupation_id,
            address: work_address,
            official_email,
            work_email_verified
          },
          has_data, last_application_date, frequently_called_numbers,
          email_verified: data.validations.email,
          whatsapp_id,
          home_address: { address: home_address, state_id: home_state }
        });
        if ((accounts || []).length === 0) this.$store.commit('patch', { new_customer: true });
        else {
          const {
            bank_id,
            card_name: account_name,
            last_four_digits: account_number,
            okra_id,
            account_card_id,
          } = accounts[accounts.length - 1];
          const bank_code = banks.find(bank => bank.id === bank_id)?.bank_code;
          const account = { bank_id, account_name, account_number, bvn, bank_code, account_card_id, okra_id };
          this.$store.commit('account', { ...account });
        }
      } catch (e) {
        this.setError('Failed to get user details, please try again');
      }
    },
    cancel() {
      parent.postMessage({ id: 'cclan-cancel' }, "*");
    },
  }
}
</script>
