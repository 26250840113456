<template>
  <div>
    <template v-if="$store.state.lender">
      <div class="mb-8 flex items-center">
        <img src="https://msbm.edu.ng/58-images/logo-dark.png" alt="" class="w-24"/>
        <span class="mx-4 text-lg">+</span>
        <img src="@/assets/images/logo.png" alt="" class="w-32"/>
      </div>
      <SectionHeader title="Data sharing consent for tuition financing by Creditclan"/>
      <p class="space-y-4 bg-gray-50 border border-gray-300 rounded-lg px-6 py-8">
        By clicking the 'Agree' button, I consent to Creditclan obtaining information from MSBM and other third parties,
        as may be necessary, on my employment details, salary payment, loans and other related data, to make a decision
        on my tuition financing application.
      </p>
      <div class="flex items-center space-x-4">
        <AppButton @click="next()" class="mt-8">I agree</AppButton>
      </div>
    </template>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button.vue";

export default {
  name: "Consent",
  components: { AppButton, SectionHeader },
  data() {
    return {
      cancelled: false
    }
  },
  methods: {
    next() {
      return this.$emit('done');
    },
    cancel() {
      this.cancelled = true;
    }
  }
}
</script>
