var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full overflow-hidden"},[_c('div',{staticClass:"flex flex-col h-full overflow-y-auto pb-16 pt-8"},[_c('div',{staticClass:"my-auto"},[_c('SectionHeader',{attrs:{"title":"Let's get to know you","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[(_vm.error)?_c('Message',{staticClass:"mb-6",attrs:{"variant":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"flex flex-col space-y-4"},[(_vm.$store.state.profile.user_id)?[_c('div',[_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Full name")]),_c('AppInput',{staticClass:"w-full",attrs:{"value":_vm.values.full_name,"type":"text","disabled":""}})],1),_c('div',[_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Email address")]),_c('AppInput',{staticClass:"w-full",attrs:{"value":_vm.values.email,"type":"email","disabled":""}})],1),_c('div',[_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Phone number")]),_c('AppInput',{staticClass:"w-full",attrs:{"value":_vm.values.phone,"type":"text","disabled":""}})],1)]:[_c('ValidationProvider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Full name")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.full_name),callback:function ($$v) {_vm.$set(_vm.values, "full_name", $$v)},expression:"values.full_name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Email address")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"email","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.email),callback:function ($$v) {_vm.$set(_vm.values, "email", $$v)},expression:"values.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Phone number","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Phone number")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"tel","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.phone),callback:function ($$v) {_vm.$set(_vm.values, "phone", $$v)},expression:"values.phone"}})]}}])})],_c('ValidationProvider',{attrs:{"name":"occupation_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Employment status")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.occupation_id),callback:function ($$v) {_vm.$set(_vm.values, "occupation_id", $$v)},expression:"values.occupation_id"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select employment status")]),_vm._l((_vm.occupations),function(status){return _c('option',{key:status.value,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])})],2)]}}])}),_c('ValidationProvider',{attrs:{"name":"Place of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Place of work")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}])})],2),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$store.state.profile.user_id ? 'Get offer' : 'Continue')+" ")])],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }