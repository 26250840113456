<template>
  <div class="h-full flex flex-col">
    <div class="flex justify-between mb-4">
      <button
        @click="popView()" v-if="history.length > 1 && !loading"
        class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
      >
        <i class="fa fa-chevron-left"></i>
      </button>
      <span v-else></span>
      <button
        @click="cancel()" v-if="inIframe"
        class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>

    <div class="my-auto">
      <template v-if="!!loading">
        <Loader
          v-if="loading === 'creating-request'" class="my-auto"
          :texts="['Getting your offer..', 'Found an offer..', 'Almost done..']"
          :timeout="5000"
        />
        <Loader v-else class="my-auto" :text="loading"/>
      </template>
      <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()"/>
      <template v-else>
        <Consent
          v-if="view === 'consent'"
          @done="pushView('course-info')"
          @skip="replaceView('course-info')"
        />
        <CourseInfo
          v-if="view === 'course-info'"
          @done="pushView('personal')"
          @skip="replaceView('personal')"
        />
        <ConsentProfile
          v-if="view === 'personal'"
          @done="pushView('picture')"
          @skip="replaceView('picture')"
        />
        <Picture
          v-if="view === 'picture'"
          @done="finish()"
          @skip="finish()"
        />
      </template>
    </div>
    <br><br>
  </div>
</template>

<script>
import Picture from "@/components/shared/Picture.vue";
import Loader from "@/components/global/Loader.vue";
import view from "@/mixins/view.js";
import ajax from "@/mixins/ajax.js";
import Error from "@/components/global/Error.vue";
import axios from "axios";
import Consent from "@/components/shared/Consent.vue";
import CourseInfo from "@/components/shared/CourseInfo.vue";
import ConsentProfile from "@/components/shared/ConsentProfile.vue";

export default {
  name: "PreAnalysisNoAuth",
  components: {
    ConsentProfile,
    CourseInfo,
    Consent,
    Error,
    Loader,
    Picture,
  },
  mixins: [view, ajax],
  data: () => ({
    inIframe: false
  }),
  created() {
    this.getBanks();
    this.inIframe = window.self !== window.top;
    this.pushView('consent');
  },
  methods: {
    async finish() {
      this.modal = '';
      let { request_id, bs } = this.$store.state;
      if (!request_id) request_id = await this.createRequest();
      if (request_id) {
        if (bs?.response) await this.submitBsReport(request_id);
        if (this.history.length > 1) await this.saveCreditclanRequestId(request_id);
      }
      if (request_id && !this.error) this.$emit('done');
    },
    async createRequest() {
      this.setLoading('creating-request');
      try {
        const { request, profile, account, work, home_address, extra, } = this.$store.state;
        const { data } = await this.$http.post('/loan/apply', {
          profile, account, work, request, home_address, ...extra
        });
        if (!data.status) {
          this.setError(data.message || 'An error occurred while creating your request, please try again', true);
          this.setRetry(() => this.finish());
          return false
        }
        this.$store.commit('patch', { request_id: data.dd });
        await this.$store.dispatch('updateStage', { stage: 'created' });
        return data.dd;
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again', true);
        this.setRetry(() => this.finish());
        return false;
      }
    },
    async submitBsReport(request_id) {
      try {
        const {
          bs: { has_online_banking, response },
          account: { account_name, account_number, bank_id }
        } = this.$store.state;
        const { data: { token } } = await this.$http.post('https://mobile.creditclan.com/api/v3/bankstatement/initiate', {
          request_id, account: { account_number, bank_id, account_name, }, has_online_banking
        });
        await this.$http.post(`https://mobile.creditclan.com/api/v3/bankstatement/update`, {
          token, analyze: true, ...response
        });
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
        this.setRetry(() => this.finish());
      }
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
    async saveCreditclanRequestId(request_id) {
      const { extra } = this.$store.state;
      await this.saveParentCcId(request_id, extra.parent_request_id);
    },
    async saveParentCcId(creditclan_request_id, parent_request_id) {
      const { profile_image } = this.$store.state.profile;
      try {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id, parent_request_id, profile_image
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async getBanks() {
      if (this.$store.state.banks_mono) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      const banks_mono = res.data.data;
      this.$store.commit('patch', { banks_mono })
    }
  }
};
</script>
