import Vue from 'vue';
import Vuex from 'vuex';
import http from "@/plugins/http";
import { addMinutes, differenceInDays } from "date-fns";
import banks from "@/lib/banks.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    request: {
      amount: 0,
      duration: 0,
      tenor_type: 2
    },
    profile: {
      user_id: null,
      full_name: '',
      email: '',
      phone: '',
      profile_image: '',
      date_of_birth: null,
      gender: null,
    },
    account: {
      bvn: null,
      account_name: '',
      account_number: '',
      bank_id: '',
      bank_code: '',
      okra_id: null,
    },
    home_address: {
      address: '',
      state_id: '',
    },
    work: {
      monthly_income: '',
      work_sector: '',
      occupation_id: '',
      address: '',
      start_month: '',
      start_year: '',
      company_name: '',
      official_email: '',
      work_email_verified: '',
    },
    identity: {
      nin: ''
    },
    has_data: false,
    questions: [],
    request_id: null,
    new_customer: false,
    token: null,
    offer: null,
    stage: '',
    bs: {
      has_online_banking: 0,
      response: null
    },
    config: {
      no_frequently_called_numbers: 2,
      analyze_bank_statement: true,
      tokenize_card: true,
      show_offers: true,
      show_questions: true,
      show_bank_account: true,
      match_account_with_bvn: false,
      show_work_information: false,
      verify_email: false,
      show_nin: true,
      scan_whatsapp: false,
      notify_whatsapp: false,
      show_signature: false,
      bs_name_match: false,
      account_name_match: false,
      recommended_upfront_cap: false,
      show_consent: false,
      always_show_offers: false,
      show_address: false,
      show_profile: false,
      verify_work_email: false
    },
    extra: {},
    last_application_date: null,
    timer_end: addMinutes(new Date(Date.now()), 5),
    timer_start: new Date(Date.now()),
    email_verified: false,
    products: [],
    lender: null,
    whatsapp_id: null,
    banks_mono: null,
    payment: null,
    consent: false
  },
  getters: {
    lastApplied: state => {
      if (!state.last_application_date) return {
        recently: false,
        twoWeeks: false,
        oneMonth: false,
        threeMonths: false
      };
      const date = new Date(state.last_application_date);
      const today = new Date(Date.now());
      const difference = differenceInDays(today, date);
      return {
        recently: difference <= 2,
        twoWeeks: difference > 2 && difference <= 14,
        oneMonth: difference > 14 && difference <= 30,
        threeMonths: difference > 30 && difference <= 60
      };
    }
  },
  mutations: {
    patch: (state, data) => {
      Object.keys(data).forEach(key => {
        state[key] = data[key];
      });
    },
    profile: (state, data) => {
      Object.keys(data).forEach(key => {
        state.profile[key] = data[key];
      });
    },
    home_address: (state, data) => {
      Object.keys(data).forEach(key => {
        state.home_address[key] = data[key];
      });
    },
    work: (state, data) => {
      Object.keys(data).forEach(key => {
        state.work[key] = data[key];
      });
    },
    account: (state, data) => {
      Object.keys(data).forEach(key => {
        state.account[key] = data[key];
      });
    },
    bs: (state, data) => {
      Object.keys(data).forEach(key => {
        state.bs[key] = data[key];
      });
    },
    social: (state, data) => {
      Object.keys(data).forEach(key => {
        state.social[key] = data[key];
      });
    },
    config: (state, data) => {
      Object.keys(data).forEach(key => {
        state.config[key] = data[key];
      });
    },
  },
  actions: {
    async postData({ state }, data) {
      const payload = { token: state.token, amount: state.request.amount, };
      if (data.type === 'phone') payload.phone = state.profile.phone;
      if (data.type === 'nin') payload.nin = state.identity.nin;
      if (data.type === 'bvn') payload.bvn = state.account.bvn;
      const res = await http.post('/customer/getdata', { ...payload, ...data });
      return res.data;
    },
    async updateStage({ state, commit }, payload) {
      const { token, request_id } = state;
      await http.post('https://mobile.creditclan.com/api/v3/request/update/stage', {
        token, request_id, ...payload
      });
      commit('patch', { stage: payload.stage });
    },
    async getUserDetails({ state, commit }) {
      try {
        const { token } = state;
        const res = await http.post('/user/detailsbyid', { token });
        const {
          profile: { file_name, phone, legal_name: full_name, email, date_of_birth, gender },
          home_address: { home_address, home_state },
          bvn: { bvn },
          work: {
            net_monthly_income, company_name, work_sector, start_month, start_year, occupation_id, work_address,
            official_email, work_email_verified
          },
          accounts
        } = res.data.data.userData.data;
        const { has_data, last_application_date, frequently_called_numbers: fcn, data, whatsapp_id } = res.data;
        let profile_image = state.profile.profile_image || file_name;
        if (profile_image === 'https://res.cloudinary.com/du1mqyww8/image/upload/v1617623098/wnwad3hebiais8ulr5yy.png') profile_image = null;
        commit('profile', { profile_image, phone, full_name, email, date_of_birth, gender });
        commit('account', { bvn });
        const frequently_called_numbers = fcn || [{ phone: '' }, { phone: '' }];
        commit('patch', {
          work: {
            monthly_income: net_monthly_income, company_name, work_sector, start_month, start_year,
            occupation_id, address: work_address, official_email, work_email_verified
          },
          has_data, last_application_date, frequently_called_numbers,
          email_verified: data.validations.email,
          whatsapp_id,
          home_address: { address: home_address, state_id: home_state }
        });
        if ((accounts || []).length === 0) commit('patch', { new_customer: true });
        else {
          const {
            bank_id, card_name: account_name, last_four_digits: account_number, okra_id, account_card_id,
          } = accounts[accounts.length - 1];
          const bank_code = banks.find(bank => bank.id === bank_id)?.bank_code;
          const account = { bank_id, account_name, account_number, bvn, bank_code, account_card_id, okra_id };
          commit('account', { ...account });
        }
      } catch (e) {
        console.log({ e });
      }
    }
  },
});
