<template>
  <div class="mb-10">
    <h2 class="text-3xl lg:text-3xl font-semibold w-11/12 text-blue-900">{{ title }}</h2>
    <p v-if="subtitle" :class="`text-gray-500 mt-${spacing} max-w-lg`">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    spacing: {
      type: String,
      default: '2'
    }
  }
}
</script>
