<template>
  <div v-if="!new_customer" class="py-14 my-auto">
    <div class="mb-10">
      <Avatar/>
    </div>
    <SectionHeader
      v-if="$store.state.token"
      :title="`Welcome back, ${$store.state.profile.full_name.split(' ')[0]}`"
      subtitle="Let's quickly get you a checkout offer"
      spacing="1"
    />
    <SectionHeader
      v-else
      title="Upload your selfie to continue"
    />
    <div class="flex mt-12">
      <AppButton @click="next()">Continue</AppButton>
    </div>
  </div>
  <div v-else class="py-14 my-auto">
    <SectionHeader
      :title="`${$store.state.profile.full_name.split(' ')[0]}, kindly take a selfie`"
      subtitle="We'd love your smile too"
    />
    <div class="mt-12">
      <Avatar/>
    </div>
    <div class="flex mt-12">
      <AppButton @click="next()">Continue</AppButton>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Avatar from "@/components/Avatar";

export default {
  name: 'Picture',
  components: { Avatar, AppButton, SectionHeader },
  created() {
    if (this.$store.state.profile.profile_image) return this.$emit('skip');
  },
  computed: {
    new_customer() {
      return this.$store.state.new_customer;
    },
  },
  methods: {
    next() {
      if (!this.$store.state.profile.profile_image) return;
      this.$emit('done');
    },
  }
};
</script>
