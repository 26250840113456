<template>
  <div>
    <template v-if="course">
      <SectionHeader title="Course Info"/>
      <div class="col-span-2">
        <img
          class="rounded-lg mb-4 w-full"
          :src="course.image || 'https://images.pexels.com/photos/1438072/pexels-photo-1438072.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'"
          alt="course"
        />
      </div>
      <ul class="border rounded-lg divide-y divide-gray-200 col-span-3">
        <li class="px-5 py-2">
          <div class="text-sm font-semibold text-gray-500 mb-1">Course title</div>
          <div class="text-sm">{{ course.title }}</div>
        </li>
        <li class="px-5 py-2">
          <div class="text-sm font-semibold text-gray-500 mb-1">Course amount</div>
          <div class="text-sm">{{ course.amount | currency }}</div>
        </li>
        <li class="px-5 py-2">
          <div class="text-sm font-semibold text-gray-500 mb-1">Course duration</div>
          <div class="text-sm">{{ course.duration }}</div>
        </li>
      </ul>
      <div class="flex items-center space-x-4">
        <AppButton @click="next()" class="mt-8">Continue</AppButton>
      </div>
    </template>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";

export default {
  name: "CourseInfo",
  components: { AppButton, SectionHeader },
  data() {
    return {
      course: this.$store.state.extra?.course
    }
  },
  created() {
    if (!this.course) return this.$emit('skip');
  },
  methods: {
    next() {
      return this.$emit('done');
    },
  }
}
</script>
