<template>
  <div v-if="loading" class="my-auto">
    <Loader :text="loading"/>
  </div>
  <Error v-else-if="!!error" :text="error" show-retry @retry="retry()"/>
  <div v-else class="my-auto">
    <template v-if="offer && offer.amount > 0">
      <SchoolOffers
        :offer="offer" @accept="handleAcceptOffer($event)" :accepting="accepting"
      />
    </template>
  </div>
</template>

<script>
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error.vue";
import Loader from "@/components/global/Loader.vue";
import SchoolOffers from "@/components/offers/SchoolOffers.vue";

export default {
  name: "Offers",
  components: { SchoolOffers, Loader, Error },
  mixins: [ajax],
  data() {
    return {
      view: 'generic',
      payment: { amount: 0, upfront: false },
      modal: '',
      accepting: false
    }
  },
  computed: {
    offer() {
      return this.$store.state.offer;
    }
  },
  async created() {
    if (!this.offer) await this.computeOffer();
  },
  methods: {
    async computeOffer() {
      const { stage, request: { amount, tenor } } = this.$store.state;
      const offer = {
        ...offer,
        original_amount: +amount,
        duration: +tenor,
        repayment_amount: +amount,
        upfront: +amount / +tenor,
        amount: +amount,
        tenor_type: 2,
        loan_amount: +amount,
        monthly_repayment: +amount / +tenor,
      };
      this.$store.commit('patch', { offer });
      if (stage !== 'offers') await this.$store.dispatch('updateStage', { stage: 'offers' });
    },
    async handleAcceptOffer(offer) {
      await this.acceptOffer(offer);
      if (this.$store.state.offer) {
        const payment = { amount: offer.upfront, upfront: true };
        this.$store.commit('patch', { payment })
        this.next();
      }
    },
    async acceptOffer(offer) {
      this.accepting = true;
      try {
        const { request_id, token } = this.$store.state;
        const payload = {
          request_id, token,
          amount: offer.loan_amount,
          repayment_amount: offer.repayment_amount,
          duration: offer.duration,
          tenor_type: offer.tenor_type
        };
        const { data } = await this.$http.post('https://mobile.creditclan.com/webapi/v1/loan/modify_offer', payload);
        await this.$store.dispatch('updateStage', {
          stage: 'offer-selected', offer_selected: offer
        });
        if (data.status) this.$store.commit('patch', { offer });
      } catch (err) {
        console.log({ err });
        this.setRetry(() => this.acceptOffer(offer));
        const message = err?.response?.data?.message || 'An error occurred, please try again';
        this.setError(message);
      }
      this.accepting = false;
    },
    next() {
      this.$emit('done');
    }
  },
}
</script>
