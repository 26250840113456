<template>
  <Loader v-if="loading" :text="loading" class="my-auto"/>
  <Error
    class="my-auto" v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()" :show-back="!!backCb"
    @back="back()"
  />
  <div v-else class="py-14 my-auto">
    <template v-if="!paid">
      <SectionHeader title="Pay the first installment of tuition amount"/>

      <Message v-if="confirmationError" variant="error" class="mb-6">{{ confirmationError }}</Message>

      <template v-if="data.amount">
        <h2 class="text-lg text-gray-700 font-bold">{{ data.amount | currency }}</h2>
        <p class="mt-5 mb-10">
          Transfer exact amount above into account details below via your Internet/Mobile banking platform and
          click on confirm transfer to verify your transfer
        </p>
        <div class="border px-6 py-3 rounded-lg">
          <div class="flex flex-col">
            <div class="flex items-center justify-between">
              <span class="text-gray-500">Bank</span>
              <span class="font-medium">{{ data.bank_name }}</span>
            </div>
            <hr class="my-3">
            <div class="flex items-center justify-between">
              <span class="text-gray-500">Account number</span>
              <span class="font-medium">{{ data.account_number }}</span>
            </div>
            <hr class="my-3">
            <div class="flex items-center justify-between">
              <span class="text-gray-500">Expires in</span>
              <span v-if="!expired" class="font-medium">
                {{ timer.hours }} : {{ timer.minutes }} : {{ timer.seconds }}
              </span>
              <span v-else class="font-medium text-red-500">Expired</span>
            </div>
          </div>
        </div>
        <div class="mt-10">
          <AppButton v-if="!expired" color="primary" elevation="0" outlined @click="confirm()">
            Confirm {{ data.amount | currency }} payment
          </AppButton>
          <AppButton v-else @click="getAccount()"><i class="fa fa-redo-alt mr-2"></i> Generate new account</AppButton>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center">
        <i class="fa fa-check-circle fa-5x text-green-500"></i>
        <p class="mt-10 text-gray-600 font-semibold text-lg">
          {{ payment.upfront ? 'Upfront payment made' : 'Payment made' }}
        </p>
        <p class="text-gray-500 mt-3">Click the button below to setup your card for repayment</p>
        <div class="mt-7 flex">
          <AppButton @click="paymentDone()">Continue</AppButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ajax from "@/mixins/ajax";
import Loader from "@/components/global/Loader";
import AppButton from "@/components/global/Button";
import Message from "@/components/global/Message";
import Error from "@/components/global/Error";
import SectionHeader from "@/components/global/SectionHeader";
import axios from "axios";

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000));
  return this;
}

export default {
  name: "VirtualAccountPayment",
  components: { SectionHeader, Error, Message, AppButton, Loader },
  mixins: [ajax],
  data() {
    return {
      data: {},
      loading: '',
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      interval: null,
      confirmationError: '',
      paid: false,
      expired: false,
      payment: this.$store.state.payment,
    }
  },
  created() {
    if (!this.payment) return this.$emit('done');
    this.checkIfUpfrontPaid();
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    async checkIfUpfrontPaid() {
      this.setLoading('Please wait');
      try {
        const { token, request_id } = this.$store.state;
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/request/check/upfront', {
          token, request_id
        });
        if (!res.data.status) return this.getAccount();
        else this.$emit('done');
      } catch (e) {
        this.setError(e?.response?.data?.message || 'An error occurred, please try again');
      }
      this.setLoading('');
    },
    async getAccount() {
      this.expired = false;
      this.setLoading('Please wait..');
      try {
        const { data } = await axios.post('https://wema.creditclan.com/generate/account', {
          merchant_name: 'Creditclan',
          narration: 'Order payment',
          amount: this.payment.amount,
          phone: this.$store.state.profile.phone,
          request_id: this.$store.state.request_id
        },);
        if (data.status) {
          this.data = data.data;
          this.startTimer();
        } else {
          this.setError(data?.message || 'An error occurred, please try again');
          this.setRetry(() => this.getAccount());
        }
      } catch (e) {
        this.setError(e.response?.data?.message || 'An error occurred, please try again');
        this.setRetry(() => this.getAccount());
      }
      this.setLoading('');
    },
    async confirm() {
      this.loading = 'Confirming transfer..';
      try {
        const { data } = await axios.post('https://wema.creditclan.com/api/v3/wema/verify_transaction', {
          transaction_reference: this.data.flw_ref
        });
        if (!data.status) this.confirmationError = data.message || data.status_desc || 'An error occurred, please try again';
        else {
          const { stage } = this.$store.state;
          if (stage !== 'upfront-paid') await this.$store.dispatch('updateStage', { stage: 'upfront-paid' });
          this.paymentDone();
        }
      } catch (e) {
        console.log({ e });
        this.confirmationError = `Unable to confirm transfer, ensure you've made the transfer and try again`;
      }
      this.loading = '';
    },
    startTimer() {
      this.countdown();
      this.interval = setInterval(() => this.countdown(), 1000);
    },
    clearTimer() {
      if (this.interval) clearInterval(this.interval);
    },
    countdown() {
      const future = new Date(this.data.expiry_date);
      const now = new Date(Date.now());
      future.addHours(1);
      if (future < now) {
        this.timer = { days: '00', hours: '00', minutes: '00', seconds: '00' }
        this.expired = true;
        return this.clearTimer();
      }
      const diff = future - now;
      this.timer.days = Math.floor(diff / (1000 * 60 * 60 * 24));
      this.timer.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.timer.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      this.timer.seconds = Math.floor((diff % (1000 * 60)) / 1000);
    },
    paymentDone() {
      this.$emit('done');
    },
  },
}
</script>
