<template>
  <div class="my-auto">
    <template v-if="banks && banks.length">
      <template v-if="view === 'accounts'">
        <SectionHeader title="Setup your repayment debit bank" subtitle="Select an account below to continue"/>
        <div @click="next()" class="border bg-gray-50 rounded p-4 cursor-pointer hover:bg-gray-100">
          <div class="text-gray-700 text-sm mb-1">{{ bank.name }}</div>
          <div class="text-gray-400">
            Account number:
            <span class="font-semibold text-gray-700">{{ values.account_number }}</span>
          </div>
          <div class="text-gray-400">
            Account name:
            <span class="font-semibold text-gray-700">{{ values.account_name }}</span>
          </div>
        </div>
        <p class="text-gray-600 mt-6">I now use
          <button class="text-blue-500" @click="change()">another account</button>
        </p>
      </template>
      <template v-if="view === 'add'">
        <SectionHeader
          title="Setup your repayment debit bank"
          subtitle="We will securely review your bank transactions to ensure you get the full credit you deserve"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <form>
          <ValidationObserver ref="observer">
            <div class="flex flex-col space-y-4">
              <ValidationProvider name="bank_code" rules="required" v-slot="{ errors }">
                <AppSelect
                  v-model="values.bank_code" :errors="errors" class="w-full"
                  :disabled="loading || validated()"
                >
                  <option disabled value="">Select bank</option>
                  <option :value="bank.bank_code" v-for="bank in banks" :key="bank.bank_id">{{ bank.name }}</option>
                </AppSelect>
              </ValidationProvider>
              <ValidationProvider name="account_number" rules="required" v-slot="{ errors }">
                <AppInput
                  v-model="values.account_number" type="number" placeholder="Account number" :errors="errors"
                  class="w-full" :disabled="loading || validated()"
                />
              </ValidationProvider>
              <div>
                <ValidationProvider name="bvn" rules="required|length:11" v-slot="{ errors }">
                  <AppInput
                    v-model="values.bvn" type="number" placeholder="BVN" :errors="errors" class="w-full"
                    :disabled="loading || validated()"
                  />
                  <small class="text-gray-500 mt-2">Dial *565*0# to get your BVN number</small>
                </ValidationProvider>
              </div>
            </div>

            <Message v-if="validated()" variant="success" class="mt-6">Account name: {{ values.account_name }}</Message>

            <div class="flex mt-10">
              <AppButton v-if="!validated()" @click="submit()" :loading="loading">Validate</AppButton>
              <template v-else>
                <AppButton @click="saveAccount()" :loading="saving">Save</AppButton>
                <button @click="change()" class="py-1 px-4 ml-2" :disabled="saving">Change</button>
              </template>
            </div>
          </ValidationObserver>
        </form>
      </template>
    </template>
    <Loader v-else text="Getting banks.." class="my-auto"/>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import Message from "@/components/global/Message";
import Loader from "@/components/global/Loader";
import { namesMatch } from "@/lib/utils.js";

export default {
  name: "BankAccount",
  components: { Loader, Message, AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      allowed: true,
      values: {
        account_number: '',
        account_name: '',
        bank_id: '',
        bank_code: '',
        bvn: ''
      },
      loading: false,
      saving: false,
      error: '',
      view: '',
      banks: this.$store.state.banks_mono,
    }
  },
  created() {
    const { analyze_bank_statement, show_bank_account } = this.$store.state.config;
    if (!analyze_bank_statement && !show_bank_account) return this.$emit('done');
    const { recently, twoWeeks, oneMonth } = this.$store.getters['lastApplied'];
    if (recently || twoWeeks || oneMonth) return this.$emit('done');
    this.getBanks();
    const {
      new_customer,
      account: { account_number, bank_id, account_name, bank_code, bvn, okra_id }
    } = this.$store.state;
    if (account_number && bank_id && !new_customer) {
      this.view = 'accounts';
      this.values = { account_number, bank_id, account_name, bank_code, bvn, okra_id };
    } else {
      this.view = 'add';
    }
  },
  methods: {
    async submit() {
      this.error = '';
      this.values.account_name = null;
      const valid = await this.$refs.observer.validate()
      if (!valid) return;
      this.loading = true;
      await this.validateAccount();
      if (!this.values.account_name) {
        this.loading = false;
        return;
      }
      this.$store.commit('patch', { account: this.values });
      this.loading = false;
    },
    setError(error) {
      this.loading = false;
      this.error = error;
    },
    async validateAccount() {
      try {
        const payload = {
          bank_code: this.values.bank_code,
          account_number: this.values.account_number,
        };
        const { data } = await this.$http.post('https://mobile.creditclan.com/webapi/v1/account/resolve', payload);
        if (data.status !== "success") return this.setError('Could not validate account, Check your details and try again');
        const { account_name } = data.data;
        const { profile: { full_name }, config: { account_name_match } } = this.$store.state;
        if (account_name_match && !namesMatch(full_name, account_name)) {
          return this.setError('Sorry, this account belongs to someone else');
        }
        this.values.bank_id = this.banks.find(bank => bank.bank_code === this.values.bank_code).id;
        this.values.account_name = account_name;
      } catch (error) {
        console.log({ error });
      }
    },
    async saveAccount() {
      try {
        this.saving = true;
        const { token, account: { account_number, bank_id, account_name, bvn } } = this.$store.state;
        const res = await this.$http.post('https://mobile.creditclan.com/api/v3/user/account/save', {
          token, account_number, account_name, bank_id, bvn
        });
        if (res.data.status) this.next();
        else this.setError(res?.data?.message || 'An error occurred, please try again');
      } catch (e) {
        this.setError(e?.response?.data?.message || 'An error occurred, please try again');
      }
      this.saving = false;
    },
    change() {
      this.view = 'add'
      this.values = {};
    },
    next() {
      if (!this.values.account_name) return;
      this.$emit('done');
    },
    async getBanks() {
      if (this.banks) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      this.banks = res.data.data;
    },
    validated() {
      return !!this.values.account_name;
    },
  },
  computed: {
    bank() {
      return this.banks.find(bank => bank.id === this.values.bank_id) || {};
    },
  }
}
</script>
