<template>
  <div class="h-full">
    <div class="flex justify-between">
      <span></span>
      <button
        @click="cancel()" v-if="inIframe"
        class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>

    <div class="h-full flex flex-col">
      <div class="my-auto">
        <Loader
          v-if="loading"
          class="my-auto"
          text="Please wait.."
        />
        <template v-else>
          <Offers
            v-if="view === 'offers'"
            @done="pushView('payment')"
          />
          <VirtualAccountPayment
            v-if="view === 'payment'"
            @done="pushView('account')"
          />
          <BankAccount
            v-if="view === 'account'"
            @done="pushView('signature')"
            @skip="pushView('signature')"
          />
          <Signature
            v-if="view === 'signature'"
            @done="next()"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Offers from "@/components/shared/Offers.vue";
import Loader from "@/components/global/Loader.vue";
import view from '@/mixins/view.js';
import VirtualAccountPayment from "@/components/shared/VirtualAccountPayment.vue";
import BankAccount from "@/components/shared/BankAccount.vue";
import Signature from "@/components/shared/Signature.vue";

export default {
  name: "PostAnalysis",
  components: {
    Signature,
    BankAccount,
    VirtualAccountPayment,
    Loader,
    Offers,
  },
  mixins: [view],
  data() {
    return {
      loading: false,
      inIframe: false
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
    const { stage, offer } = this.$store.state;
    if (stage === 'offers' && offer) this.pushView('offers');
    else if (stage === 'offer-selected') this.pushView('payment');
    else if (stage === 'upfront-paid') this.pushView('account');
    else if (stage === 'completed') this.next();
    else {
      this.pushView('offers');
    }
  },
  methods: {
    next() {
      this.$emit('done');
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
  }
};
</script>
