<template>
  <div class="h-full flex flex-col">
    <template v-if="view === 'pre-analysis'">
      <PreAnalysisAuth v-if="$store.state.profile.user_id" @done="preAnalysisDone()"/>
      <PreAnalysisNoAuth v-else @done="preAnalysisDone()"/>
    </template>
    <PostAnalysis v-if="view === 'post-analysis'" @done="postAnalysisDone()"/>
    <Success v-if="view === 'success'"/>
  </div>
</template>

<script>
import PreAnalysisAuth from "@/components/core/PreAnalysisAuth.vue";
import view from '@/mixins/view.js';
import PostAnalysis from "@/components/core/PostAnalysis.vue";
import Success from "@/components/shared/Success.vue";
import PreAnalysisNoAuth from "@/components/core/PreAnalysisNoAuth.vue";

export default {
  name: "Analysis",
  components: { PreAnalysisNoAuth, Success, PostAnalysis, PreAnalysisAuth },
  mixins: [view],
  created() {
    const { request_id } = this.$store.state;
    if (request_id) return this.pushView('post-analysis');
    this.pushView('pre-analysis');
  },
  methods: {
    preAnalysisDone() {
      this.pushView('post-analysis');
    },
    postAnalysisDone() {
      this.pushView('success');
    },
  }
}
</script>
